<template>
     <div className="animated fadeIn">
       <div id="snackbar"></div>
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of all Cinemas
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
           <div slot="CinemaIsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.CinemaIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.CinemaID)"/>            
          </div>

          <div slot="CinemaID" slot-scope="props">
            <i class="fa fa-edit" @click="editCinema(props.row.CinemaID)"></i>
          </div>

          <div slot="CinemaChargesID" slot-scope="props">
            <i v-if="props.row.CinemaChargesID" class="fa fa-edit" @click="editCinemaCharges(props.row.CinemaChargesID)"></i>
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>    
</template>
<script>
import { Event } from "vue-tables-2";
import { Switch as cSwitch } from "@coreui/vue";
import CinemaService from "@/services/CinemaService";
import MasterService from "@/services/MasterService";

export default {
  name: "AllCinemas",
  components: {
    cSwitch,
    Event
  },
  data: function() {
    return {
      columns: ["CinemaName", "CinemaIsActive", "CinemaID", "CinemaChargesID"],
      data: [],
      options: {
        headings: {
          CinemaName: "Cinema",
          CinemaIsActive: "Status",
          CinemaID: "Edit Cinema",
          CinemaChargesID: "Edit Charges"
        },
        sortable: ["CinemaName"],
        filterable: ["CinemaName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    this.getCinemas(0);
  },
  methods: {
    getCinemas: function(OrgID) {
      this.data = [];
      let payload = {
        OrgID
      };
      CinemaService.getCinemas(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.data = data.CinemaList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editCinema: function(cinemaID) {
      this.$router.push({
        path: "/org-management/cinemas/add-cinema",
        query: { cinemaID }
      });
    },
    changeStatus: function(ID) {
      let payload = {
        TableName: "Cinema",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editCinemaCharges: function(CinemaChargesID) {
      this.$router.push({
        path: "/org-management/cinemas/add-cinema-charges",
        query: { CinemaChargesID }
      });
    },
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
